import cx from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React from "react"
import { Helmet } from "react-helmet"
import Footer from "../components/Footer"
import Form from "../components/Form"
import Hero from "../components/Hero"
import Menu from "../components/Menu"
import SquadAllocation from "../components/SquadAllocation"
import arrowScroll from "../images/arrow-scroll.svg"
import { infoHeroSolutionDigitals, infoHeroTechnologies } from "../mock/data"
import imgSite from "../images/thumgSite"

//Componente References será finalizado posteriormente
//import References from "../components/Reference"

const HomePage = () => {
  const gridClasses = cx("m-auto main-container")
  const title = "eNe Soluções"
  const thumbSite = "https://ene-solucoes.s3.amazonaws.com/thumbSite.png"
  
  return (
    <>
      <Helmet title={title}>
        {/* Primary Meta Tags */}
        <title>eNe Soluções</title>
        <meta name="title" content="eNe Soluções" />
        <meta name="description" content="A eNe Soluções é especialista em UI/UX, desenvolvimento Front-end, SEO e BIZ. Criamos com base nos três pilares: a experiência do usuário, os objetivos do negócio do cliente e o desenvolvimento da programação ideal com as melhores tecnologias para atingir os resultados." />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://enesolucoes.com.br/" />
        <meta property="og:title" content="eNe Soluções" />
        <meta property="og:description" content="A eNe Soluções é especialista em UI/UX, desenvolvimento Front-end, SEO e BIZ. Criamos com base nos três pilares: a experiência do usuário, os objetivos do negócio do cliente e o desenvolvimento da programação ideal com as melhores tecnologias para atingir os resultados." />
        <meta property="og:image" content={thumbSite} />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://enesolucoes.com.br/" />
        <meta property="twitter:title" content="eNe Soluções" />
        <meta property="twitter:description" content="A eNe Soluções é especialista em UI/UX, desenvolvimento Front-end, SEO e BIZ. Criamos com base nos três pilares: a experiência do usuário, os objetivos do negócio do cliente e o desenvolvimento da programação ideal com as melhores tecnologias para atingir os resultados." />
        <meta property="twitter:image" content={thumbSite} />
      </Helmet>
      <div className={gridClasses}>
        <Menu />
        <Hero {...infoHeroSolutionDigitals} />
        <div className="sm:hidden md:flex justify-center ">
          <AnchorLink to="/#contato">
            <img src={arrowScroll} alt="Scroll Button" />
          </AnchorLink>
        </div>
      </div>
      <SquadAllocation />
      <div className={gridClasses}>
        <Hero {...infoHeroTechnologies} />
        {/* <Techs /> */}
        {/* <References /> */}
        <Form />
        <Footer />
      </div>
    </>
  )
}
export default HomePage
