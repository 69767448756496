import cx from "classnames"
import React from "react"

import * as styles from "./styles.module.css"

interface CardProps {
  title: string
  description: string
  icon: string
  iconAlt: string
  index: number
}

const Cards = ({ title, description, icon, iconAlt, index }: CardProps) => {
  const cardsClass = cx(
    "w-11/12 text-white hover:bg-cyan duration-1000 border-2 border-solid border-ebonyClay sm:px-4 sm:mb-6 md:mb-9 py-8 md:px-12",
    styles.cards
  )
  const iconClass = cx("mb-4", styles.icon)
  const titleClass = cx("text-white font-bold text-2xl mb-2.5 leading-6", styles.text)
  const descriptionClass = cx("sm:text-sm md:text-base my-0", styles.text)

  return (
    <div className={cardsClass} key={index}>
      <img src={icon} alt={iconAlt} className={iconClass} />
      <h1 className={titleClass}>{title}</h1>
      <p className={descriptionClass}>{description}</p>
    </div>
  )
}
export default Cards
