import React from "react"
import cx from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import * as styles from "./styles.module.css"

interface ButtonProps {
  text: string
  toLink: string
}

const AnchorButton = ({ text, toLink }: ButtonProps) => {
  const buttonClass = cx(
    "flex items-center justify-center bg-cyan sm:w-full md:w-12/12 lg:w-8/12 xl:w-8/12 hover:bg-opacity-80 hover:text-opacity-80",
    styles.button
  )

  return (
    <div className={buttonClass}>
      <AnchorLink
        className="text-darkBlue font-bold hover:text-darkBlue "
        to={toLink}
      >
        {text}
      </AnchorLink>
    </div>
  )
}
export default AnchorButton
