import React from "react"
import Cards from "../Cards"

interface DataProps {
  title: string
  description: string
  icon: string
  iconAlt: string
}
interface CardProps {
  data: DataProps[]
}

const Card = ({ data }: CardProps) => {
  return (
    <div className="sm:flex md:grid lg:grid md:grid-cols-2 lg:grid-cols-3 sm:gap-x-3 lg:gap-x-4 xl:gap-x-8">
      {data.map((item, index) => (
        <div key={index}>
          <Cards
            title={item.title}
            icon={item.icon}
            iconAlt={item.iconAlt}
            description={item.description}
            index={index}
          />
        </div>
      ))}
    </div>
  )
}
export default Card
