import cx from "classnames"
import React from "react"

import {
  badgesSquadAllocation, cardsSquadAllocation, infoSquadAllocation
} from "../../mock/data"
import AnchorButton from "../AnchorButton"
import Badges from "../Badges"
import Card from "../Card"
import * as styles from "./styles.module.css"

const SquadAllocation = () => {
  const gridClasses = cx("m-auto main-container")
  const containerAllocationClass = cx("md:mt-16", styles.containerAllocation)
  const containerClass = cx("m-auto sm:pt-8 md:pt-28")
  const containerInfoClass = cx(
    "sm:mt-3 sm:mb-6 md:mt-3.5 md:mb-11",
    styles.containerInfoAllocation
  )

  return (
    <section className={containerAllocationClass} id="servicos">
      <div className={gridClasses}>
        <div className={containerClass}>
          <div className="flex">
            {badgesSquadAllocation.map((item, index) => (
              <div key={index}>
                <Badges text={item.text} index={index} icon={item.icon} />
              </div>
            ))}
          </div>
          <div className={containerInfoClass}>
            <h1
              className="xl:text-7xl md:text-5xl mb-0 text-white font-bold md:w-4/5"
              dangerouslySetInnerHTML={{ __html: infoSquadAllocation.title }}
            />
          </div>
          <div className="sm:flex flex-row md:block w-full sm:overflow-x-auto md:overflow-hidden">
            <Card data={cardsSquadAllocation} />
          </div>
          <div className="flex justify-center pb-16">
            <div className="sm:mt-3 md:mt-6 sm:w-full md:w-7/12 lg:w-5/12 xl:w-4/12 flex justify-center">
              <AnchorButton
                text={infoSquadAllocation.textButton}
                toLink="/#contato"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default SquadAllocation
