import React from "react";
import "./style.less";

interface options {
  id: number
  text: string
}

interface selectCustomProps{
  placeholder: string,
  onChange: (f: string) => void
  value: string,
  options: Array<options>
}

export default function SelectCustom(props: selectCustomProps) {
  return(
    <select
      className="selectCustom"
      style={props.value ? {color: "#0a2437"} : {color: "#67737e"}}
      placeholder={props.placeholder}
      onChange={props.onChange}
      defaultValue=""
      value={props.value}
      >
      
      <option className="custom-option" disabled value="">
        {props.placeholder}
      </option>
      {props.options?.map((item, index) => (
        <option key={item.id} className="custom-option" value={item.text}>
          {item.text}
        </option>
      ))}
    </select>
  )
}